import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Pages/Dashboard/dashboard'
import UserTheme from './Pages/Dashboard/userTheme'
import {
  Home,
  Login,
  Signup,
  ForgotPassword,
  ResetPassword,
  Fields,
  AddFields,
  AdminAboutUs,
  AdminContactUS,
  AdminPrivacyPolicy,
  AdminTermsAndConditions,
  Groups,
  AddGroup,
  AdminDashboard,
  AboutUs,
  ContactUs,
  PrivacyPolicy,
  TermsAndConditions,
  Capability,
  PricingPlan,
  ConfirmEmail,
  ChangePassword,
  PagePreview,
} from 'Pages'
import AddPricingPlan from 'Pages/Admin/PricingPlan/add'
import FormList from 'Pages/Admin/Forms/list'
import AddForm from 'Pages/Admin/Forms/AddForm'
import PageList from 'Pages/Admin/Pages/list'
import AddPage from 'Pages/Admin/Pages/AddPage'
import AdminList from 'Pages/Admin/EntityManagement/Admin/list'
import UserList from 'Pages/Admin/EntityManagement/User/list'
import ClientList from 'Pages/Admin/EntityManagement/Client/list'
import BusinessList from 'Pages/Admin/EntityManagement/Business/list'
import AdminPageConfig from 'Pages/Admin/PageConfig/Admin/list'
import UserPageConfig from 'Pages/Admin/PageConfig/User/list'
import ClientPageConfig from 'Pages/Admin/PageConfig/Client/list'
import Config from 'Pages/Admin/Config/config'
import GroupConditions from 'Pages/Admin/Condition/conditions'
import BusinessPageConfig from 'Pages/Admin/PageConfig/Business/list'
import MyProfile from 'Pages/Admin/MyProfile'
import PDFConfigList from 'Pages/Admin/PDF/Config/list'
import PDFTemplateList from 'Pages/Admin/PDF/Template/list'
import CreateCustomPDFPage from 'Pages/Admin/PDF/Config/add'
import EditBusiness from 'Pages/Admin/EntityManagement/Business/business'
import PDFPageTypes from 'Pages/Admin/PDF/Type/list'
import PDFPageLayoutList from 'Pages/Admin/PDF/Type/listLayout'
import AddPDFPageLayout from 'Pages/Admin/PDF/Type/addLayout'
import QuestionnaireListing from 'Pages/Questionnaire/questionnaire'
import AddQuestionnaire from 'Pages/Questionnaire/add'
import QuestionnaireWizard from 'Pages/Questionnaire/wizard'
import QuestionnaireData from 'Pages/Questionnaire/data'
import WizardListing from 'Pages/Wizard/report'
import WizardData from 'Pages/Wizard/data'
import AddPDFTemplate from 'Pages/Admin/PDF/Template/add'
import ReportsList from 'Pages/Report/list'
import AddPDFReport from 'Pages/Report/add'
import WorkBook from 'Pages/Workbook/workbook'
import PortFolioListing from 'Pages/Portfolio/list'
import PortfolioRecommendation from 'Pages/Portfolio/recommendation'
import RiskProfile from 'Pages/RiskProfile/risk'
import InvestmentOptionList from './Pages/InvestmentOption/list'
import InvestmentOptionHistoryList from './Pages/InvestmentOption/history_list'
import TaskFlow from 'Pages/Flow/flow'
import ReportHistory from 'Pages/Admin/Pages/ReportHistory'
import WizardHistory from 'Pages/Admin/Pages/WizardHistory'
import StrategySelection from 'Pages/Strategy/strategy'
import KnowledgeArea from 'Pages/Strategy/knowledgeArea'
import AdviceStrategyScenario from './Pages/Strategy/adviceStrategyScenerio'
import InvestmentPortfolio from 'Pages/Investment/investment'
import ProviderPrice from './Pages/ProductConfig/ProviderPrice'
import ProductAssetAllocation from './Pages/ProductConfig/ProductAssetAllocation'
import ProductFeeCharge from './Pages/ProductConfig/ProductFeeCharge'
import InvestmentOptionTable from 'Pages/InvestmentOption/table'

export default (routes) => {
  return (
    <Routes>
      {/* Public pages */}
      <Route exact path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/confirm-email/:user_type/:email/:token/" element={<ConfirmEmail />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

      {/* Logged in user Routes */}
      <Route path="/user/dashboard" element={<Dashboard />} />
      <Route path="/user/theme" element={<UserTheme />} />
      <Route path="/user/profile" element={<MyProfile />} />
      <Route path="/user/page/:pageId" element={<PagePreview />} />
      <Route path="/preview/user/page/:pageId" element={<PagePreview preview />} />
      <Route path="/preview/user/page/:pageId/report-history" element={<ReportHistory preview />} />
      <Route path="/preview/user/page/:pageId/wizard-history" element={<WizardHistory preview />} />
      <Route path="/preview/user/wizard" element={<QuestionnaireWizard preview />} />

      <Route path="/preview/user/dashboard" element={<Dashboard preview />} />
      <Route path="/preview/user/theme" element={<UserTheme preview />} />
      <Route path="/preview/user/profile" element={<MyProfile preview />} />
      <Route path="/preview/user/page/:pageId" element={<PagePreview preview />} />

      {/* Admin routes */}
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/fields" element={<Fields />} />
      <Route path="/admin/fields/add" element={<AddFields />} />
      <Route path="/admin/fields/edit/:id" element={<AddFields />} />
      <Route path="/admin/groups" element={<Groups />} />
      <Route path="/admin/groups/add" element={<AddGroup />} />
      <Route path="/admin/groups/edit/:id" element={<AddGroup />} />
      <Route path="/admin/forms" element={<FormList />} />
      <Route path="/admin/forms/add" element={<AddForm />} />
      <Route path="/admin/forms/edit/:formId" element={<AddForm />} />
      <Route path="/admin/pages" element={<PageList />} />
      <Route path="/admin/entity-management/admin" element={<AdminList />} />
      <Route path="/admin/entity-management/user" element={<UserList />} />
      <Route path="/admin/entity-management/client" element={<ClientList />} />
      <Route path="/admin/entity-management/business" element={<BusinessList />} />
      <Route path="/admin/entity-management/business/edit" element={<EditBusiness />} />
      <Route
        path="/admin/entity-management/business/edit/:id"
        element={<EditBusiness preview={true} />}
      />
      <Route path="/admin/page-config/admin" element={<AdminPageConfig />} />
      <Route path="/admin/page-config/user" element={<UserPageConfig />} />
      <Route path="/admin/page-config/business" element={<BusinessPageConfig />} />
      <Route path="/admin/page-config/client" element={<ClientPageConfig />} />
      <Route path="/admin/pages/add" element={<AddPage />} />
      <Route path="/admin/pages/edit/:pageId" element={<AddPage />} />
      <Route path="/admin/pages/about-us" element={<AdminAboutUs />} />
      <Route path="/admin/pages/contact-us" element={<AdminContactUS />} />
      <Route path="/admin/config-builder" element={<Config />} />
      {/* Pass condition item details with allowed variables to receive data from condition component */}
      <Route path="/admin/pdf-config/pages" element={<PDFConfigList />} />
      <Route path="/admin/pdf-config" element={<PDFTemplateList />} />
      <Route path="/admin/pdf-config/add" element={<AddPDFTemplate />} />
      <Route path="/admin/pdf-config/edit/:template_id" element={<AddPDFTemplate />} />
      <Route path="/admin/pdf-config/add-page" element={<CreateCustomPDFPage />} />
      <Route path="/admin/pdf-config/edit-page/:config_page_id" element={<CreateCustomPDFPage />} />

      <Route path="/admin/pdf-config/page-type" element={<PDFPageTypes />} />
      <Route
        path="/admin/pdf-config/default-page/list/:page_config_id/:page_name"
        element={<PDFPageLayoutList />}
      />
      <Route
        path="/admin/pdf-config/default-page/add/:page_config_id/:page_name"
        element={<AddPDFPageLayout />}
      />
      <Route
        path="/admin/pdf-config/default-page/edit/:page_config_id/:page_name/:layout_id"
        element={<AddPDFPageLayout />}
      />
      <Route path="/admin/conditions" element={<GroupConditions />} />
      <Route path="/reports" element={<ReportsList />} />
      <Route path="/reports/add" element={<AddPDFReport />} />
      <Route path="/investment-option" element={<InvestmentOptionList />} />
      <Route path="/investment-option-view" element={<InvestmentOptionTable />} />
      <Route path="/investment-option-history" element={<InvestmentOptionHistoryList />} />
      <Route path="/admin/pages/privacy-policy" element={<AdminPrivacyPolicy />} />
      <Route path="/product-price" element={<ProviderPrice />} />
      <Route path="/product-fee-charge" element={<ProductFeeCharge />} />
      <Route path="/product-asset-allocation" element={<ProductAssetAllocation />} />
      <Route path="/strategy" element={<StrategySelection />} />
      <Route path="/knowledge-area" element={<KnowledgeArea />} />
      <Route path="/preview/user/advice-strategy-scenario" element={<AdviceStrategyScenario />} />
      <Route path="/wealth-portfolio" element={<InvestmentPortfolio />} />
      <Route path="/admin/questionnaire" element={<QuestionnaireListing />} />
      <Route path="/admin/questionnaire/data" element={<QuestionnaireData />} />
      <Route path="/admin/questionnaire/add" element={<AddQuestionnaire />} />
      <Route path="/admin/questionnaire/edit/:questionnaire_id" element={<AddQuestionnaire />} />
      <Route path="/wizard" element={<WizardListing />} />
      <Route path="/workbook" element={<WorkBook />} />
      <Route path="/task-flow" element={<TaskFlow />} />
      <Route path="/portfolio" element={<PortFolioListing />} />
      <Route path="/portfolio-recommendation" element={<PortfolioRecommendation />} />

      <Route path="/wizard/data" element={<WizardData />} />
      <Route path="/admin/questionnaire/wizard" element={<QuestionnaireWizard />} />
      <Route path="/risk-profile" element={<RiskProfile />} />
      <Route path="/admin/pages/terms-and-conditions" element={<AdminTermsAndConditions />} />
      <Route path="/admin/setting/capability" element={<Capability />} />
      <Route path="/admin/setting/pricing-plan" element={<PricingPlan />} />

      <Route path="/admin/setting/pricing-plan/add" element={<AddPricingPlan />} />
      <Route path="/admin/setting/pricing-plan/edit/:id" element={<AddPricingPlan />} />
    </Routes>
  )
}
