import { Input, Table, Button, Modal, Space, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { AdminLayout } from 'Components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const { Option } = Select

const InvestmentOptionTable = (props) => {
  let navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);


  // State to manage table data
  const [dataSource, setDataSource] = useState([
    {
      id: 1,
      position: 'Position 1',
      account: 'Account 1',
      current_balance: '$5000',
      proposed_balance: '$7000',
      strategy: 'Auto',
      drp: 'Yes',
      option: '',
      optionsList: ['Option A1', 'Option A2', 'Option A3'], // Options specific to this record
    },
    {
      id: 2,
      position: 'Position 2',
      account: 'Account 2',
      current_balance: '$8000',
      proposed_balance: '$9000',
      strategy: 'Open deposit',
      drp: 'No',
      option: '',
      optionsList: ['Option B1', 'Option B2', 'Option B3'],
    },
  ])

  // State to manage table data
  const [dataSource1, setDataSource1] = useState([
    {
      id: 1,
      position: 'Position 1',
      investment_option: 'Account 1',
      current_balance: '$5000',
      proposed_balance: '$7000',
      strategy: 'Auto',
      drp: 'Yes',
      option: '',
      optionsList: ['Option A1', 'Option A2', 'Option A3'], // Options specific to this record
    },
    {
      id: 2,
      position: 'Position 2',
      investment_option: 'Account 2',
      current_balance: '$8000',
      proposed_balance: '$9000',
      strategy: 'Open deposit',
      drp: 'No',
      option: '',
      optionsList: ['Option B1', 'Option B2', 'Option B3'],
    },
  ])

  // Function to add a new record
  const handleAddRecord = () => {
    const newRecord = {
      id: dataSource.length + 1,
      position: `Position ${dataSource.length + 1}`,
      account: `Account ${dataSource.length + 1}`,
      current_balance: '0 USD',
      proposed_balance: '0 USD',
      strategy: `Strategy ${dataSource.length + 1}`,
      drp: 'No',
      option: '',
      optionsList: [`Option C1`, `Option C2`, `Option C3`],
    }
    setDataSource([...dataSource, newRecord])
  }

  const handleAddRecord1 = () => {
    const newRecord = {
      id: dataSource1.length + 1,
      position: `Position ${dataSource1.length + 1}`,
      investment_option: `Account ${dataSource1.length + 1}`,
      current_balance: '100 USD',
      proposed_balance: '30 USD',
      strategy: `Strategy ${dataSource1.length + 1}`,
      drp: 'No',
      option: '',
      optionsList: [`Option C1`, `Option C2`, `Option C3`],
    }
    setDataSource1([...dataSource1, newRecord])
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
    },
    {
      title: 'Current Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
    },
    {
      title: 'Proposed Balance',
      dataIndex: 'proposed_balance',
      key: 'proposed_balance',
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy',
      key: 'strategy',
    },
    {
      title: 'Select Option',
      dataIndex: 'option',
      key: 'option',
      render: (text, record) => (
        <Select
          style={{ width: 150 }}
          placeholder="Select an option"
          value={record.option}
        >
          {record.optionsList.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Investment Option',
      key: 'investment_option',
      render: (text, record) => (<>
        <Button type="link" size='small' onClick={showModal}>Retain</Button>
        <Button type="text" size='small'>Review</Button>
      </>
      ),
    },
    {
      title: 'DRP',
      dataIndex: 'drp',
      key: 'drp',
    },
    {
      title: '',
      key: 'action',
      render: (text, record, index) => (
        // Add button only on the last row
        index === dataSource.length - 1 ? (
          <Button onClick={handleAddRecord}  shape="circle" icon={<PlusOutlined />} />
        ) : null
      ),
    },
  ]

  const columns1 = [
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Investment Option',
      dataIndex: 'investment_option',
      key: 'investment_option',
    },
    {
      title: 'Current Balance',
      dataIndex: 'current_balance',
      key: 'current_balance',
    },
    {
      title: 'Change',
      render: (text, record, index) => (
        <Input/>
      ),
    },
    {
      title: 'Proposed Balance',
      dataIndex: 'proposed_balance',
      key: 'proposed_balance',
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy',
      key: 'strategy',
    },
    {
      title: '',
      key: 'action',
      render: (text, record, index) => (
        // Add button only on the last row
        index === dataSource1.length - 1 ? (
          <Button onClick={handleAddRecord1}  shape="circle" icon={<PlusOutlined />} />
        ) : null
      ),
    },
  ]
  

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Investment Options</span>{' '}
        </h1>
        <br />
        <Table
          className="custom_responsive_table"
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: 50,
            PageSize: 50,
          }}
        />
        <Modal title="Retain/Review" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1100}>
          <Table
            className="custom_responsive_table"
            rowKey="idx"
            columns={columns1}
            dataSource={dataSource1}
            pagination={{
              showSizeChanger: false,
              defaultPageSize: 50,
              PageSize: 50,
            }}
          />
        </Modal>
      </div>
    </AdminLayout>
  )
}

export default InvestmentOptionTable
